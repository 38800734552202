const pizzas = [
    {
      id: 1,
      name: "Pizza P | 1 Sabor | 4 Fatias",
      price: 35.0,
      especial: 50.0,
      nobre: 65.0,
      size: "Pequena",
      imagem: "./one.png",
    },
    {
      id: 2,
      name: "Pizza M | 2 Sabores | 8 fatias",
      price: 55.0,
      especial: 75.0,
      nobre: 85.0,
      size: "Media",
      imagem: "./two.png",
    },
    {
      id: 3,
      name: "Pizza G | 4 Sabores | 12 Fatias + Bebida Grátis",
      price: 85.0,
      especial: 89.0,
      nobre: 98.0,
      size: "Grande",
      imagem: "./four.png",
    },
  ];

export default pizzas;
