import React from "react";
import Navbar from "../NavBar";

import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroP,
} from "./HeroMenuElements";

const Hero = () => {
  return (
    <HeroContainer>
      <Navbar />
      <HeroContent>
        <HeroItems>
          <HeroP>Faça seu pedido</HeroP>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};
export default Hero;
