import React from "react";
import {
  SectionContainer,
  SectionContent,
  SectionH1,
  SectionP,
  SectionBtn,
} from "./SectionElements";

const Section = () => {
  return (
    <SectionContainer>
      <SectionContent>
        <SectionH1>Avalie Seu Pedido</SectionH1>
        <SectionP>Gostou? Avalie já</SectionP>
        <a href="https://www.google.com/maps/place/BIG+BURGUER+DELIVERY/@-28.3241903,-48.7289935,15z/data=!4m8!3m7!1s0x9526b3468176118d:0xe3ca7f7417f34c3d!8m2!3d-28.3241903!4d-48.7289935!9m1!1b1!16s%2Fg%2F11sqr1tt03?entry=ttu">
          <SectionBtn>Avaliar</SectionBtn>
        </a>
      </SectionContent>
    </SectionContainer>
  );
};

export default Section;
