import React from "react";
import Navbar from "../NavBar";

import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <HeroContainer>
      <Navbar />
      <HeroContent>
        <HeroItems>
          <HeroH1>Spicy pizza delivery</HeroH1>
          <HeroP>A melhor pizza de imbituba</HeroP>
          <Link to="https://instadelivery.com.br/spicypizza">
            <HeroBtn>Fazer Pedido</HeroBtn>
          </Link>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};
export default Hero;
