import React from "react";
import { Nav, NavLink } from "./NavbarElements";

const Navbar = () => {
  const logoStyles = {
    maxWidth: "320px",
    height: "auto",
    position: "relative", // Defina a posição como relativa
    zIndex: 1, // Defina um valor de z-index maior do que o valor padrão (que é 0)
  };

  return (
    <>
      <Nav>
        <NavLink to="/">
          <img
            src="logo-spicy.png"
            alt="Logo"
            style={logoStyles} // Estilos em linha
          />
        </NavLink>
      </Nav>
    </>
  );
};
export default Navbar;
