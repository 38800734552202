const sabores = [
  // salgada
  {
    sabor: "A moda do chef",
    descricao: "mussarela, bacon, milho, parmesão e catupiry",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Calabresa",
    descricao: "mussarela e calabresa",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Portuguesa",
    descricao: "mussarela, presunto, ovos, cebola, pimentão e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Frango com catupiry",
    descricao: "mussarela, frango, catupiry e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Milho",
    descricao: "mussarela, milho e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Bacon",
    descricao: "mussarela, bacon e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Alho e óleo",
    descricao: "mussarela, alho, óleo e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Brócolis",
    descricao: "mussarela, brócolis, creme de leite e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Lombo",
    descricao: "mussarela, lombo e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "4 queijos",
    descricao: "mussarela, parmesão, prato e catupiry",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Vegetariana",
    descricao: "mussarela, tomate, milho e brócolis",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Marguerita",
    descricao: "mussarela, tomate, manjericão e orégano",
    especial: false,
    tipo: "salgada",
    imagem: "./media.png",
  },
  // Especiais Salgadas
  {
    sabor: "Spicy",
    descricao: "pimentão, Doritos, pepperoni, pimenta e cebola",
    especial: true,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Tomate seco",
    descricao: "mussarela, tomate seco, rúcula e orégano",
    especial: true,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Filé na mostarda",
    descricao: "filé, mostarda, mussarela",
    especial: true,
    tipo: "nobre",
    imagem: "./media.png",
  },
  {
    sabor: "Filé ao molho Barbecue",
    descricao: "molho de tomate, queijo mussarela, Filé Mignon, Barbecue e orégano",
    especial: true,
    tipo: "nobre",
    imagem: "./media.png",
  },
  {
    sabor: "Camarão",
    descricao: "mussarela, camarão e orégano",
    especial: true,
    tipo: "nobre",
    imagem: "./media.png",
  },
  {
    sabor: "Peperoni",
    descricao: "mussarela, peperoni e orégano",
    especial: true,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Coração",
    descricao: "mussarela, coração e orégano",
    especial: true,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Strogonoff de frango",
    descricao: "Frango ao molho de Strogonoff, mussarela e batata palha",
    especial: true,
    tipo: "salgada",
    imagem: "./media.png",
  },
  {
    sabor: "Strogonoff de carne",
    descricao: "carne ao molho de Strogonoff, mussarela e palha",
    especial: true,
    tipo: "nobre",
    imagem: "./media.png",
  },
  {
    sabor: "Strogonoff de camarão",
    descricao: "camarão ao molho de Strogonoff, mussarela e palha",
    especial: true,
    tipo: "nobre",
    imagem: "./media.png",
  },
  {
    sabor: "Manhattan",
    descricao: "cheddar, bacon crocante, pepperoni e queijo parmesão",
    especial: true,
    tipo: "salgada",
    imagem: "./media.png",
  },
  // Doces
  {
    sabor: "Chocolate preto",
    descricao: "Chocolate preto",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Chocolate branco",
    descricao: "Chocolate branco",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Banana nevada",
    descricao: "Chocolate branco e banana",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Brigadeiro",
    descricao: "Chocolate preto e granulado",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Mms",
    descricao: "Chocolate preto e mms",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Dois amores",
    descricao: "Chocolate branco e preto",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Paçoca",
    descricao: "Chocolate branco com paçoca",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Charge",
    descricao: "Chocolate preto com charge",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Oreo",
    descricao: "Chocolate preto com oreo",
    especial: false,
    tipo: "doce",
    imagem: "./doce.png",
  },
  // Especiais Doces
  {
    sabor: "Sedução",
    descricao: "Chocolate forneável branco com morango",
    especial: true,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Sensação",
    descricao: "Chocolate forneável preto com morango",
    especial: true,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Nutella",
    descricao: "Chocolate de Nutella ou avelã",
    especial: true,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Leite ninho",
    descricao: "Chocolate de leite ninho",
    especial: true,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Três desejos",
    descricao: "doce de leite, chocolate branco e chocolate preto",
    especial: true,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Romeu e Julieta",
    descricao: "Queijo e forneável de goiabada",
    especial: true,
    tipo: "doce",
    imagem: "./doce.png",
  },
  {
    sabor: "Abacaxi com coco",
    descricao: "Chocolate branco, abacaxi e COCO",
    especial: true,
    tipo: "doce",
    imagem: "./doce.png",
  },
];

export default sabores;
