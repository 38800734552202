// SectionElements.jsx
import styled from "styled-components";
import ImgBg from "../../images/spicy-section.jpg";

export const SectionContainer = styled.div`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent),
    linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent),
    linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent), url(${ImgBg});
  height: 300px;
  background-position: center;
  background-size: cover;
`;

export const SectionContent = styled.div`
  height: 100%;
  max-height: 100%;
  padding: 0rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
`;

export const SectionH1 = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  box-shadow: 3px 5px #e9ba23;
  letter-spacing: 3px;
`;

export const SectionP = styled.p`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const SectionBtn = styled.button`
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  border: none;
  background: #ff0000;
  color: #fff;
  transition: 0.2s ease-out;
  border-radius: 3px;

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    color: #000;
    cursor: pointer;
  }
`;

export const SectionImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 20px;
`;
