import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyle } from "./globalStyles";
import AppRoutes from "./Routes"; // Importe suas rotas

export default function App() {
  return (
    <Router>
      <GlobalStyle />
      <AppRoutes /> {/* Renderize suas rotas aqui */}
    </Router>
  );
}
