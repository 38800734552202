import React, { useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormGroup,
  FormControl,
  InputGroup,
  DropdownButton,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import sabores from "./sabores"; // Importe a constante sabores aqui
import pizzas from "./pizzas"; // Importe a constante sabores aqui
import bebidas from "./bebidas"; // Importe a constante sabores aqui
import SaborCard from "./SaborCard";
import BebidaCard from "./BebidaCard";

const Menu = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPizza, setSelectedPizza] = useState(null);
  const [selectedSabores, setSelectedSabores] = useState([]);
  const [selectedBebidas, setSelectedBebidas] = useState([]);
  const [selectedBorda, setSelectedBorda] = useState(""); // Estado para a borda selecionada
  const [observacoes, setObservacoes] = useState(""); // Estado para as observações

  const maxSaboresMap = {
    Pequena: 1,
    Media: 2,
    Grande: 4, // Ajustei o número máximo de sabores para 4
  };

  const openModal = (pizza) => {
    setSelectedPizza(pizza);
    setSelectedSabores([]);
    setSelectedBebidas([]);
    setSelectedBorda(""); // Limpe a seleção de borda ao abrir o modal
    setObservacoes(""); // Limpe as observações ao abrir o modal
    setShowModal(true);
  };

  const closeAndClearModal = () => {
    setSelectedPizza(null);
    setSelectedSabores([]);
    setSelectedBebidas([]);
    setSelectedBorda("");
    setObservacoes("");
    setShowModal(false);
  };

  const handleSaborSelect = (e) => {
    const selectedOption = e.target.value;
    if (e.target.checked) {
      if (selectedSabores.length < maxSaboresMap[selectedPizza.size]) {
        setSelectedSabores([...selectedSabores, selectedOption]);
      } else {
        e.target.checked = false;
      }
    } else {
      setSelectedSabores(
        selectedSabores.filter((sabor) => sabor !== selectedOption)
      );
    }
  };

  const handleBebidaSelect = (bebida) => {
    if (selectedBebidas.some((item) => item.nome === bebida.nome)) {
      setSelectedBebidas(
        selectedBebidas.filter((item) => item.nome !== bebida.nome)
      );
    } else {
      setSelectedBebidas([...selectedBebidas, bebida]);
    }
  };

  const handleProsseguirNoWhatsapp = () => {
    const mensagem = `Olá! Gostaria de fazer um pedido:%0A%0A${
      selectedPizza.name
    } - Tamanho: ${selectedPizza.size}%0ASabores: ${selectedSabores.join(
      ", "
    )}%0ABebidas: ${selectedBebidas.map(
      (bebida) => `${bebida.nome}`
    )}%0ABorda: ${selectedBorda}%0AObservações: ${observacoes}`;
    const whatsappLink = `https://wa.me/554899688585?text=${mensagem}`;
    window.location.href = whatsappLink;
  };

  return (
    <Container>
      <Row
        className="justify-content-center"
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
      >
        {pizzas.map((pizza) => (
          <Col md={3} style={{ paddingTop: "20px" }} key={pizza.id}>
            <Card>
              <Card.Body>
                <Card.Img variant="top" src={pizza.imagem} />
                <Card.Title>{pizza.name}</Card.Title>
                <Card.Text style={{ fontSize: "14px", marginBottom: "0" }}>
                  Com sabores Tradicionais: R$ {pizza.price}
                </Card.Text>
                <Card.Text style={{ fontSize: "14px", marginBottom: "0" }}>
                  Com sabores Especiais: R$ {pizza.especial}
                </Card.Text>
                <Card.Text style={{ fontSize: "14px", marginBottom: "0" }}>
                  Com sabores Nobres: R$ {pizza.nobre}
                </Card.Text>
                <Button
                  style={{ marginTop: "10px" }}
                  variant="success"
                  onClick={() => openModal(pizza)}
                >
                  Ver sabores
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal show={showModal} onHide={closeAndClearModal}>
        <Modal.Header closeButton>
          <Modal.Title>Seleção de Sabores</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Col>
              <div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Salgadas</Accordion.Header>
                    <Accordion.Body>
                      {sabores.map((saborObj) => (
                        <>
                          {saborObj.tipo === "salgada" && (
                            <SaborCard
                              key={saborObj.sabor}
                              saborObj={saborObj}
                              handleSaborSelect={handleSaborSelect}
                              selectedSabores={selectedSabores}
                            />
                          )}
                        </>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Nobres</Accordion.Header>
                    <Accordion.Body>
                      {sabores.map((saborObj) => (
                        <>
                          {saborObj.tipo === "nobre" && (
                            <SaborCard
                              key={saborObj.sabor}
                              saborObj={saborObj}
                              handleSaborSelect={handleSaborSelect}
                              selectedSabores={selectedSabores}
                            />
                          )}
                        </>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Doces</Accordion.Header>
                    <Accordion.Body>
                      {sabores.map((saborObj) => (
                        <>
                          {saborObj.tipo === "doce" && (
                            <SaborCard
                              key={saborObj.sabor}
                              saborObj={saborObj}
                              handleSaborSelect={handleSaborSelect}
                              selectedSabores={selectedSabores}
                            />
                          )}
                        </>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <FormGroup>
              <Modal.Title style={{ marginTop: "5px" }}>
                Selecione a bebida
              </Modal.Title>
              {bebidas.map((bebida) => (
                <BebidaCard
                  key={bebida.nome}
                  bebida={bebida}
                  handleBebidaSelect={handleBebidaSelect}
                  selectedBebidas={selectedBebidas}
                />
              ))}
            </FormGroup>

            {/* Adicione o campo de seleção da borda */}
            <FormGroup>
              <Form.Label>Selecione a borda:</Form.Label>
              <DropdownButton
                as={InputGroup.Append}
                variant="outline-secondary"
                title={selectedBorda || "Escolha a borda"}
                id="input-group-dropdown-1"
              >
                <Dropdown.Item
                  onClick={() => setSelectedBorda("Borda Tradicional")}
                >
                  Borda Tradicional
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setSelectedBorda("Borda Catupiry")}
                >
                  Borda Catupiry
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setSelectedBorda("Borda Cheddar")}
                >
                  Borda Cheddar
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setSelectedBorda("Chocolate ao leite ")}
                >
                  Chocolate ao leite{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setSelectedBorda("Chocolate branco")}
                >
                  Chocolate branco
                </Dropdown.Item>
                {/* Adicione mais opções de borda, se necessário */}
              </DropdownButton>
            </FormGroup>

            {/* Adicione o campo de observação */}
            <FormGroup>
              <Form.Label>Observações:</Form.Label>
              <FormControl
                as="textarea"
                rows={3}
                placeholder="Digite suas observações aqui..."
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
              />
            </FormGroup>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeAndClearModal}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleProsseguirNoWhatsapp}>
            Prosseguir no WhatsApp
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Menu;
