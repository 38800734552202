import React from "react";
import { FooterContainer, SocialIcons } from "./FooterElements";
import { FaInstagram, FaWhatsapp } from "react-icons/fa"; // Importe os ícones do Instagram e WhatsApp

const Footer = () => {
  return (
    <FooterContainer>
      <p>Spicy Pizza © 2023 - Todos os direitos reservados</p>
      <SocialIcons>
        <a href="https://www.instagram.com/spicyypizza/" target="_blank" rel="noopener noreferrer">
          <FaInstagram /> {/* Ícone do Instagram */}
        </a>
        <a href="https://wa.me/554899688585" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp /> {/* Ícone do WhatsApp */}
        </a>
      </SocialIcons>
    </FooterContainer>
  );
};

export default Footer;
