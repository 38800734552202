import React from "react";
import { Card, Form } from "react-bootstrap";

const SaborCard = ({
  saborObj,
  handleSaborSelect,
  selectedSabores,
}) => {
  const descricaoStyle = {
    fontSize: "14px",
    marginBottom: 0
  };

  const checkboxStyle = {
    marginRight: "10px",
    display: "flex",
    alignItems: "center", // Centraliza verticalmente
  };

  return (
    <Card style={{ marginTop: "5px" }}>
      <Card.Body>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>
            <Card.Img
              variant="top"
              src={saborObj.imagem}
              style={{ width: "75px", height: "75px", objectFit: "cover" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Card.Title>{saborObj.sabor}</Card.Title>
            <Card.Text style={descricaoStyle}>Sabor: {saborObj.descricao}</Card.Text>
            <Card.Text style={descricaoStyle}>{saborObj.especial ? "Especial" : "Tradicional"}</Card.Text>
          </div>
          <div style={checkboxStyle}>
            <Form.Check
              controlId={`sabor-${saborObj.sabor}`}
              type="checkbox"
              value={saborObj.sabor}
              onChange={handleSaborSelect}
              checked={selectedSabores.includes(saborObj.sabor)}
              style={{ width: "20px", height: "20px", paddingLeft: "5px" }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SaborCard;
