import React from "react";
import { Card, Form } from "react-bootstrap";

const BebidaCard = ({ bebida, handleBebidaSelect, selectedBebidas }) => {
  const descricaoStyle = {
    fontSize: "14px",
    marginBottom: 0,
  };

  const checkboxStyle = {
    marginRight: "10px",
    display: "flex",
    alignItems: "center", // Centraliza verticalmente
  };

  return (
    <Card style={{ marginTop: "5px" }}>
      <Card.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Card.Img
              variant="top"
              src={bebida.imagem}
              style={{ width: "75px", height: "75px", objectFit: "cover" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Card.Title>{bebida.nome}</Card.Title>
            <Card.Text style={descricaoStyle}>{bebida.descricao}</Card.Text>
          </div>
          <div style={checkboxStyle}>
            <Form.Check
              controlId={`bebida-${bebida.nome}`}
              type="checkbox"
              value={bebida.nome}
              onChange={() => handleBebidaSelect(bebida)}
              checked={selectedBebidas.some(
                (item) => item.nome === bebida.nome
              )}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BebidaCard;
