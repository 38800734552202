// FooterElements.jsx
import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #000;
  color: #fff;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SocialIcons = styled.div`
  margin-top: 20px;
  font-size: 24px;

  a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;

    &:hover {
      color: #007bff; /* Cor de destaque ao passar o mouse */
    }
  }
`;
