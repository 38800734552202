// HomePage.jsx
import React, { useEffect } from "react";

import HeroMenu from "../components/HeroMenu";
import Menu from "../components/Menu";
import Footer from "../components/Footer";

const Pedidos = () => {
  useEffect(() => {
    // Redirecionar para um site externo assim que a página for carregada
    window.location.href = "https://instadelivery.com.br/spicypizza";
  }, []);

  return (
    <>
      <HeroMenu />
      <Menu />
      <Footer />
    </>
  );
};

export default Pedidos;
