// AboutElements.jsx
import styled from "styled-components";

export const SectionContainer = styled.div`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent),
    linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent),
    linear-gradient(to left, rgba(0, 0, 0, 0.1), transparent), black;
  background-position: center;
  background-size: cover;
`;

export const SectionContent = styled.div`
  height: 100%;
  max-height: 100%;
  padding: 0rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;

  /* Adicione os estilos de largura e centralização aqui */
  width: 100%; /* Ajuste a largura desejada aqui */
  max-width: 1000px; /* Define um máximo para manter o texto legível */
  margin: 0 auto; /* Centraliza horizontalmente */
`;

export const SectionH1 = styled.h1`
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const SectionP = styled.p`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`;

export const SectionImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  height: 350px;
`;