// HomePage.jsx
import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Section from "../components/Section";
import Footer from "../components/Footer";

const HomePage = () => {
  return (
    <>
      <Hero />
      <About />
      <Section />
      <Footer />
    </>
  );
};

export default HomePage;
