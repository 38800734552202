// index.jsx
import React from "react";
import {
  SectionContainer,
  SectionContent,
  SectionH1,
  SectionP,
  SectionImage,
} from "./AboutElements";

const About = () => {
  return (
    <SectionContainer>
      <SectionContent>
        <SectionH1>Olá, nós somos a Spicy Pizza!</SectionH1>
        <SectionP>
          Estamos no ramo de delivery há mais de 3 anos, sempre levando sabor à
          casa de nossos clientes com nosso segmento de hamburgueria.{" "}
        </SectionP>
        <SectionP>
          Em 2023, decidimos adicionar uma atração extra ao seu paladar:
          deliciosas pizzas, quentinhas e com a mesma qualidade que você já
          conhece.
        </SectionP>
        <SectionP>
          Nossa história é uma jornada de amor pela gastronomia e pelo prazer de
          servir refeições que unem famílias e amigos. Começamos como uma
          hamburgueria, onde desenvolvemos hambúrgueres suculentos que
          conquistaram o coração de nossos clientes.{" "}
        </SectionP>
        <SectionP>
          Agora, expandimos nossos horizontes para incluir um cardápio de pizzas
          cuidadosamente elaborado, feito com os melhores ingredientes e cozido
          com perfeição. Nossa equipe está comprometida em garantir que cada
          fatia de pizza seja uma experiência gastronômica memorável.{" "}
        </SectionP>
        <SectionP>
          Agradecemos por confiar em nós ao longo desses anos e estamos
          empolgados em continuar a satisfazer seu paladar com nossas pizzas
          deliciosas. Junte-se a nós para uma jornada culinária que celebra o
          sabor e a qualidade, uma fatia de cada vez.
        </SectionP>
        <SectionImage
          src="./bigburger.png"
          alt="Descrição da imagem"
        />
      </SectionContent>
    </SectionContainer>
  );
};

export default About;
