// Routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Pedidos from "./pages/Pedidos";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/pedidos" element={<Pedidos />} />
    </Routes>
  );
}
